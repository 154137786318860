// src/Table.js
import React, { useState } from "react";
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy } from "react-table";
import { plowFileViewer, classNames, Loading } from "./shared/Utils";
import { SortIcon, SortDownIcon, SortUpIcon } from "./shared/Icons";
import { PlowButton } from "./shared/Button";
import { Modal } from "flowbite-react";
// import FileViewer from "react-file-viewer";
import { Document, Page } from "react-pdf/dist/esm/entry.parcel2";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <label className="flex gap-x-2 items-baseline">
        <span className="text-gray-700">Search: </span>
        <input
        type="text"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50 placeholder:italic"
        value={value || ""}
        onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        />
    </label>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
        <span className="text-gray-700">{render("Header")}: </span>
        <select
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
            name={id}
            id={id}
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
    </label>
  );
}

export function FileCell({ value, column, row }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }
  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);
  const fileDownload = () => {
    window.location = row.original[column.linkAccessor];
  }

  const displayPdf = async () => {
    setIsLoading(true);
    setShowModal(true);
    try {
      const response = await fetch(`${plowFileViewer.pdf.endpoint}?code=${plowFileViewer.pdf.code}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          link: row.original[column.linkAccessor]
        })
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.text();

      setData(result);
      setPdfLink(`data:application/pdf;base64,${result}`);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center">
        <div className="text-sm font-medium text-gray-900">
          <a className="underline underline-offset-2 hover:no-underline hover:text-plowgreen" onClick={e => {
            e.preventDefault();
            if (value.includes('pdf')) {
              displayPdf()
            } else {
              fileDownload()
            }
          }} href={row.original[column.linkAccessor]}>{value}</a>
          <Modal
            show={showModal}
            size="3xl"
            onClose={() => setShowModal(false)}
          >
            <Modal.Header>
              {value}
            </Modal.Header>
            <Modal.Body>
              {isLoading && <Loading />}
              {err && (
                <div>{`There is a problem fetching the post data - ${error}`}</div>
              )}
              {data && <div className="space-y-6 p-6 h-96 overflow-scroll">
                <Document
                  file={pdfLink}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                        key={`page_${row.index + 1}`}
                        pageNumber={pageNumber}
                      />
                </Document>
              </div>}
            </Modal.Body>
            <Modal.Footer>
              <PlowButton onClick={fileDownload}>
                Download
              </PlowButton>
              <div className="w-full flex justify-end items-center">
                <button
                  className={classNames(
                  "relative inline-flex items-center h-8 px-2 py-2 border border-plowgreen text-sm font-medium uppercase rounded-md text-white bg-plowgreen hover:bg-green-700")}
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                >
                  Previous
                </button>
                <div className={classNames("px-2 text-sm font-sm text-gray-700")}>
                  Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </div>
                <button
                  className={classNames(
                  "relative inline-flex items-center h-8 px-2 py-2 border border-plowgreen text-sm font-medium uppercase rounded-md text-white bg-plowgreen hover:bg-green-700")}
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                >
                  Next
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
    </div>
  );
}

export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("active") ? "bg-green-100 text-green-700" : null,
        status.startsWith("inactive") ? "bg-yellow-100 text-yellow-700" : null,
        status.startsWith("offline") ? "bg-red-100 text-red-700" : null
      )}
    >
      {status}
    </span>
  );
}

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable({
    columns,
    data,
  },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  // Render the UI for your table
  return (
    <>
        <div className="flex gap-x-2">
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) =>
                column.Filter ? (
                <div key={column.id}>
                    {column.render("Filter")}
                </div>
                ) : null
            )
            )}
        </div>
        <div className="mt-2 flex flex-col">
            <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-plowgreen">
                                {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                    <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
                                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <div className="group flex items-center justify-between">
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <SortDownIcon className="w-4 h-4 text-white" />
                                                    : <SortUpIcon className="w-4 h-4 text-white" />
                                                : <SortIcon className="w-4 h-4 text-white opacity-0 group-hover:opacity-100" />}
                                            </span>
                                        </div>
                                    </th>
                                    ))}
                                </tr>
                                ))}
                            </thead>
                            <tbody
                                {...getTableBodyProps()}
                                className="bg-white divide-y divide-gray-200"
                            >
                                {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className="even:bg-green-50" {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                        <td
                                            {...cell.getCellProps()}
                                            className="px-6 py-4 whitespace-nowrap"
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                        );
                                    })}
                                    </tr>
                                );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Table;