import 'regenerator-runtime/runtime';
import React, { useState, useEffect } from "react";
import Table, { SelectColumnFilter, FileCell } from "./Table";
import { Loading, plowFileViewer } from "./shared/Utils";

export function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${plowFileViewer.files.endpoint}?code=${plowFileViewer.files.code}&CompanyID=${plowFileViewer.files.companyId}`);
        if (!response.ok) {
          throw new Error(
            `HTTP Error: ${response.status}`
          );
        }
        let actualData = await response.json();
        setData(actualData);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, []);

  // console.log(getData(data));

  const columns = React.useMemo(
    () => [
      {
        Header: "File Name",
        accessor: "Name",
        Cell: FileCell,
        linkAccessor: "DownloadLink",
      },
      {
        Header: "Type",
        accessor: "Type",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Created",
        accessor: "Created",
        Cell: ({ cell: { value } }) => <div>{new Date(value).toLocaleDateString()}</div>,
        sortType: (rowA, rowB, columnId) => {
          let [a, b] = [new Date(rowA.values[columnId]), new Date(rowB.values[columnId])];

          a = a ? a.getTime() : null;
          b = b ? b.getTime() : null;

          return a === b ? 0 : a > b ? 1 : -1;
        },
      },
      {
        Header: "Updated",
        accessor: "Updated",
        Cell: ({ cell: { value } }) => <div>{new Date(value).toLocaleDateString()}</div>,
        sortType: (rowA, rowB, columnId) => {
          let [a, b] = [new Date(rowA.values[columnId]), new Date(rowB.values[columnId])];

          a = a ? a.getTime() : null;
          b = b ? b.getTime() : null;

          return a === b ? 0 : a > b ? 1 : -1;
        },
      },
    ],
    []
  );

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
        <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
            <div className="">
                <h1 className="text-xl text-plowgreen font-semibold">Plow Networks File Viewer</h1>
            </div>
            <div className="mt-4">
              {loading && <Loading />}
              {error && (
                <div>{`There is a problem fetching the post data - ${error}`}</div>
              )}
              {data && <Table columns={columns} data={data} />}
            </div>
        </main>
    </div>
  );
}

// export default App;