import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useId } from 'react';
import { excludeClassName } from '../../helpers/exclude';
import { Badge } from '../Badge';
import { useTheme } from '../Flowbite/ThemeContext';
import { Tooltip } from '../Tooltip';
import { useSidebarContext } from './SidebarContext';
import { useSidebarItemContext } from './SidebarItemContext';
const SidebarItem = ({ as: Component = 'a', children, icon: Icon, active: isActive, label, labelColor = 'info', ...props }) => {
    const theirProps = excludeClassName(props);
    const id = useId();
    const { isCollapsed } = useSidebarContext();
    const { isInsideCollapse } = useSidebarItemContext();
    const theme = useTheme().theme.sidebar.item;
    const Wrapper = ({ children: Component }) => (_jsx("li", { children: isCollapsed ? (_jsx(Tooltip, { content: _jsx(Children, { children: children }), placement: "right", children: Component })) : (Component) }));
    const Children = ({ children }) => (_jsx("span", { className: classNames(theme.content.base), "data-testid": "flowbite-sidebar-item-content", id: `flowbite-sidebar-item-${id}`, children: children }));
    return (_jsx(Wrapper, { children: _jsxs(Component, { "aria-labelledby": `flowbite-sidebar-item-${id}`, className: classNames(theme.base, isActive && theme.active, !isCollapsed && isInsideCollapse && theme.collapsed.insideCollapse), ...theirProps, children: [Icon && (_jsx(Icon, { "aria-hidden": true, className: classNames(theme.icon.base, isActive && theme.icon.active), "data-testid": "flowbite-sidebar-item-icon" })), !isCollapsed && _jsx(Children, { children: children }), label && !isCollapsed && (_jsx(Badge, { color: labelColor, "data-testid": "flowbite-sidebar-label", hidden: isCollapsed, children: label }))] }) }));
};
SidebarItem.displayName = 'Sidebar.Item';
export default SidebarItem;
