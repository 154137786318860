const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const companyId = urlParams.get('CompanyID') || "176";

export const plowFileViewer = {
  files: {
    endpoint: "https://cloudradialsharepointbridge.azurewebsites.net/api/SharePointFilesAPI",
    code: "Jk6hXbm_85OJi2m_Yi6H1lcqanRpCZ5lEov4vYIS1-GbAzFusccWIA==",
    companyId: companyId,
  },
  pdf: {
    endpoint: "https://cloudradialsharepointbridge.azurewebsites.net/api/Convert-ToBase64",
    code: "HULw0KyvN2npA66bj56rJuExCPBhXD-aOKZLhZP9tz_rAzFuekClzg==",
  }
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function Loading() {
    return (
        <h2 className="flex justify-center items-center mx-auto px-4 py-2 font-semibold leading-6 text-lg rounded-md text-plowgreen transition ease-in-out duration-150 cursor-not-allowed" disabled>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-plowgreen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Loading...
        </h2>
    );
}